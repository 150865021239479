import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.lastCheckedId = null;
    this.lastCheckedState = false;
  }

  toggleSelection(event) {
    if (this.lastCheckedId === event.target.dataset.id && this.lastCheckedState) {
      event.target.checked = false;
      this.lastCheckedId = null;
      this.lastCheckedState = false;
    } else {
      this.lastCheckedId = event.target.dataset.id;
      this.lastCheckedState = event.target.checked;
    }
  }
}
