import { Controller } from "@hotwired/stimulus";

// This controller allows toggling between a button and a form field that can be used to edit the value of a model field.
export default class extends Controller {
  static targets = ["button", "fields", "form"];

  showField() {
    this.buttonTarget.classList.add("hidden");
    this.fieldsTarget.classList.remove("hidden");
    const field = this.findField();
    field.value = field.dataset.defaultValue;
    field.focus();
    if (field.type === "date" || field.type === "time" || field.type === "datetime-local") {
      field.showPicker();
    }
  }

  hideField() {
    this.buttonTarget.classList.remove("hidden");
    this.fieldsTarget.classList.add("hidden");
    this.findField().value = "";
  }

  submit(event) {
    this.formTarget.requestSubmit();
    if (event.target.value === "") {
      this.hideField();
    }
  }

  findField() {
    return this.fieldsTarget.querySelector("input:not([type='hidden'])");
  }
}
