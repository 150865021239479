import { BridgeComponent, BridgeElement } from "@hotwired/hotwire-native-bridge";

export default class extends BridgeComponent {
  static component = "overflow-menu";
  static targets = ["item"];

  connect() {
    super.connect();

    this.notifyBridgeOfConnect();
  }

  notifyBridgeOfConnect() {
    const label = this.bridgeElement.title;

    const items = this.makeMenuItems(this.itemTargets);

    this.send("connect", { label, items }, (message) => {
      const selectedIndex = message.data.selectedIndex;
      const selectedItem = new BridgeElement(this.itemTargets[selectedIndex]);

      selectedItem.click();
    });
  }

  makeMenuItems(elements) {
    const items = elements.map((element, index) => this.menuItem(element, index));
    const enabledItems = items.filter((item) => item);

    return enabledItems;
  }

  menuItem(element, index) {
    const bridgeElement = new BridgeElement(element);

    if (bridgeElement.disabled) return null;

    return {
      title: bridgeElement.title,
      index: index,
    };
  }
}
