import { BridgeComponent } from "@hotwired/hotwire-native-bridge";

export default class extends BridgeComponent {
  static component = "right-bar-button";

  connect() {
    super.connect();

    this.notifyBridgeOfConnect();
  }

  notifyBridgeOfConnect() {
    const label = this.bridgeElement.title;
    const icon = this.bridgeElement.bridgeAttribute("icon");

    this.send("connect", { label, icon }, () => {
      this.bridgeElement.click();
    });
  }

  disconnect() {
    this.notifyBridgeOfDisconnect();
    super.disconnect();
  }

  notifyBridgeOfDisconnect() {
    const label = this.bridgeElement.title;
    const icon = this.bridgeElement.bridgeAttribute("icon");

    this.send("disconnect", { label, icon }, () => {});
  }
}
